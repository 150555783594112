export function removeItem(items, item) {
  let index = [...items].findIndex(_item => _item.id == item.id);
  if (index != -1) {
    items.splice(index, 1);
  }
  return items
}

export function replaceItem(items, item) {
  let index = [...items].findIndex(_item => _item.id == item.id);
  if (index != -1) {
    items.splice(index, 1, item);
  }
  return items
}

export function insertItem(items, item) {
  let index = [...items].findIndex(_item => _item.id == item.id);
  if (index == -1) {
    items.splice(0, 0, item);
  }
  return items
}

export default { removeItem, replaceItem, insertItem }