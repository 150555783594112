export default {
  path: "/",
  component: () => import("@/layouts/BaseLayout"),
  children: [
    {
      path: "inventory_report",
      meta: { title: "库存报表" },
      component: () => import("@/views/inventoryReport/index"),
    },
    {
      path: "stock_in_report",
      meta: { title: "入库报表" },
      component: () => import("@/views/stockInReport/index"),
    },
    {
      path: "stock_out_report",
      meta: { title: "出库报表" },
      component: () => import("@/views/stockOutReport/index"),
    },
    {
      path: "inventory_warning",
      meta: { title: "库存预警" },
      component: () => import("@/views/inventoryWarning/index"),
    },
  ],
};
