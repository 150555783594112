export default {
  path: "/",
  component: () => import("@/layouts/BaseLayout"),
  children: [
    {
      path: "stock_in_label",
      component: () => import("@/views/stockInLabel/index"),
    },
  ],
};
