export default {
  path: "/",
  component: () => import("@/layouts/BaseLayout"),
  children: [
    {
      path: "location_transfer_record",
      meta: { title: "移库" },
      component: () => import("@/views/locationTransferRecord/index"),
    },
  ],
};
