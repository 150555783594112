export default {
  path: "/",
  component: () => import("@/layouts/BaseLayout"),
  children: [
    {
      path: "role",
      meta: { title: "角色管理" },
      component: () => import("@/views/role/index"),
    },
    {
      path: "user_account",
      meta: { title: "员工管理" },
      component: () => import("@/views/userAccount/index"),
    },
    {
      path: "label_config",
      meta: { title: "标签配置" },
      component: () => import("@/views/labelConfig/index"),
    },
    {
      path: "mobile_download",
      meta: { title: "移动端下载" },
      component: () => import("@/views/mobileDownload/index"),
    },
  ],
};
