import VueRouter from "vue-router";
import user from "./user";
import system from "./system";
import data from "./data";
import stockIn from "./stockIn";
import stockOut from "./stockOut";
import stockTransfer from "./stockTransfer";
import report from "./report";
import label from "./label";

const index = {
  path: "/",
  component: () => import("@/layouts/BaseLayout"),
  redirect: "/home",
  children: [
    {
      path: "home",
      meta: { title: "首页" },
      component: () => import("@/views/home/index"),
    },
  ],
};

const routes = [index, user, system, data, stockIn, stockOut, stockTransfer, report, label];

export default new VueRouter({ mode: "history", routes });
