export default {
  path: "/",
  component: () => import("@/layouts/BaseLayout"),
  children: [
    {
      path: "stock_in_record",
      meta: { title: "入库" },
      component: () => import("@/views/stockInRecord/index"),
    },
  ],
};
