export default {
  path: "/",
  component: () => import("@/layouts/BaseLayout"),
  children: [
    {
      path: "stock_out_record",
      meta: { title: "出库" },
      component: () => import("@/views/stockOutRecord/index"),
    },
  ],
};
