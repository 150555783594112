export default {
  state: () => ({
    username: "",
    isManager: false,
    permissions: [],
    config: {},
    barConfig: {},
    currentWarehouse: undefined,
  }),
  mutations: {
    setUser(state, item) {
      state.username = item.username;
      state.isManager = item.is_manager;
      state.permissions = item.permissions;
      state.barConfig = {
        locationLabelSize: item.location_label_size,
        materialLabelSize: item.material_label_size,
        stockInLabelSize: item.stock_in_label_size,
      };
      if (item.configs) {
        for (let config of item.configs) {
          state.config[config.type] = config.strategy;
        }
      }
    },
    setWarehouse(state, value) {
      state.currentWarehouse = value;
    },
    setBarConfig(state, value) {
      state.barConfig = {
        locationLabelSize: value.location_label_size,
        materialLabelSize: value.material_label_size,
        stockInLabelSize: value.stock_in_label_size,
      };
    },
  },
};
