export default {
  path: "/user",
  component: () => import("@/layouts/UserLayout"),
  redirect: '/user/login',
  children: [
    {
      path: "login",
      meta: { title: "登录" },
      component: () => import("@/views/login/index"),
    },
    {
      path: "set_password",
      meta: { title: "设置密码" },
      component: () => import("@/views/setPassword/index"),
    },
  ],
};
