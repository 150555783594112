export default {
  path: "/",
  component: () => import("@/layouts/BaseLayout"),
  children: [
    {
      path: "warehouse",
      meta: { title: "仓库管理" },
      component: () => import("@/views/warehouse/index"),
    },
    {
      path: "area",
      meta: { title: "库区管理" },
      component: () => import("@/views/area/index"),
    },
    {
      path: "location",
      meta: { title: "库位管理" },
      component: () => import("@/views/location/index"),
    },
    {
      path: "client",
      meta: { title: "客户管理" },
      component: () => import("@/views/client/index"),
    },
    {
      path: "category",
      meta: { title: "产品分类" },
      component: () => import("@/views/category/index"),
    },
    {
      path: "material",
      meta: { title: "产品管理" },
      component: () => import("@/views/material/index"),
    },
  ],
};
